const logger = document.getElementById('seatmap-webview-postmessages') as HTMLDivElement;

export const emitSeatmapEvent = (eventType, payload = {}) => {
  if (!window.ReactNativeWebView && !logger) return;

  const message = { type: eventType, payload };
  console.debug('emitSeatmapEvent', message);

  if (logger) {
    logger.innerHTML = `<div class="message">SEND: ${JSON.stringify(message)}</div> ${
      logger.innerHTML
    }`;
  }

  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  } catch (e) {
    console.warn(e);
  }
};
