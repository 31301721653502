import React, { useCallback, useEffect, useState } from 'react';
import { addSVGClass, removeSVGClass } from '@/svg-class';
import { SeatTooltip } from './SeatTooltip';
import SeatmapMap from './SeatmapMap';
import { ZoomControls } from './ZoomControls';
import {
  handleResetZoom,
  handleZoomIn,
  handleZoomOut,
  initSeatmapInteraction,
} from './interaction';
import { useSeatmapStore } from './seatmapStore';
import { ZoomTransform } from 'd3';
const ACTIVE_CLASS = 'seatmap-seat-active';

interface Props {
  handleAddSeat: (itemId: string, qid: string) => void;
  handleMapReady: () => void;
  handleRemoveSeat: (qid: string) => void;
  onSelectQID: (qid: string | undefined) => void;
  hideTooltips?: boolean;
  path: string;
}

export const PublicSeatmapMapContainer: React.FC<Props> = (props) => {
  const {
    handleAddSeat: propHandleAddSeat,
    handleMapReady: propHandleMapReady,
    handleRemoveSeat: propHandleRemoveSeat,
    onSelectQID,
    hideTooltips,
    path,
  } = props;

  // const seatSvg = useRef<SVGElement>();
  const [seatSvg, setSeatSvg] = useState<SVGElement | undefined>(undefined);
  const [currentTransform, setCurrentTransform] = useState<ZoomTransform>(null);

  const { selectedQid } = useSeatmapStore();

  const clearSelectedSeat = useCallback(() => {
    if (seatSvg) {
      removeSVGClass(seatSvg, ACTIVE_CLASS);
    }
    if (selectedQid) onSelectQID(undefined);
  }, [onSelectQID, selectedQid, seatSvg]);

  const handleAddSeat = useCallback(
    (itemId: string, qid: string) => {
      if (!qid) {
        console.warn('PSMC.handleAddSeat called without qid');
        return;
      }
      propHandleAddSeat(itemId, qid);
      clearSelectedSeat();
    },
    [propHandleAddSeat, clearSelectedSeat],
  );

  const handleRemoveSeat = useCallback(() => {
    if (!selectedQid) {
      console.warn('PSMC.handleRemoveSeat called without selectedQid');
    } else {
      propHandleRemoveSeat(selectedQid);
    }
    clearSelectedSeat();
  }, [selectedQid, propHandleRemoveSeat, clearSelectedSeat]);

  const handleMapReady = useCallback(() => {
    initSeatmapInteraction('.seatmap-map-container', setCurrentTransform);
    propHandleMapReady();
  }, [propHandleMapReady]);

  const handleCloseTooltip = useCallback(() => {
    clearSelectedSeat();
  }, [clearSelectedSeat]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      const target = event.target as HTMLElement | SVGElement;
      const qid = target.getAttribute('data-qid');
      if (!qid) {
        clearSelectedSeat();
        return;
      }

      // always fire even if we've already got qid === selectedQid to re-open
      // windows in the webview
      onSelectQID(qid);
    },
    [clearSelectedSeat, onSelectQID],
  );

  useEffect(() => {
    if (!selectedQid) {
      // nothing selected, clear any existing and exit
      if (seatSvg) {
        removeSVGClass(seatSvg, ACTIVE_CLASS);
        setSeatSvg(undefined);
      }
      return;
    }

    const target = document.querySelector<SVGElement>(`.seatmap-map [data-qid="${selectedQid}"]`);

    if (seatSvg && target !== seatSvg) {
      removeSVGClass(seatSvg, ACTIVE_CLASS);
    }

    if (!target.classList.contains(ACTIVE_CLASS)) {
      addSVGClass(target, ACTIVE_CLASS);
    }

    if (target !== seatSvg) {
      setSeatSvg(target);
    }
  }, [selectedQid, seatSvg]);

  return (
    <div className="seatmap-map-container" onClick={onClick}>
      <SeatmapMap path={path} handleMapReady={handleMapReady} />
      <ZoomControls
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleResetZoom={handleResetZoom}
      />
      {!hideTooltips && (
        <SeatTooltip
          selectedQid={selectedQid}
          seatElement={seatSvg}
          currentTransform={currentTransform}
          handleAddSeat={handleAddSeat}
          handleRemoveSeat={handleRemoveSeat}
          handleClose={handleCloseTooltip}
        />
      )}
    </div>
  );
};
PublicSeatmapMapContainer.displayName = 'PublicSeatmapMapContainer';
export default PublicSeatmapMapContainer;
