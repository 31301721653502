import { reportError } from '@/common/errorReporting';
import { CART_UPDATED_EVENT_TYPE } from '@/frontend/cart';
import { CartData } from '@/frontend/types';
import * as ActionCable from '@rails/actioncable';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import CheckoutButton from './CheckoutButton';
import LargeCloseButton from './LargeCloseButton';
import PublicSeatmapMapContainer from './PublicSeatmapMapContainer';
import { SeatmapHeader } from './SeatmapHeader';
import { emitSeatmapEvent } from './emitSeatmapEvent';
import seatDataToEmit from './seatDataToEmit';
import * as SeatmapApi from './seatmapApi';
import { useSeatmapStore } from './seatmapStore';
import { TicketList } from './ticket_list';
import type { PossibleItems, SeatData, SeatmapView } from './types';

const SEAT_CLASSES = {
  a: 'seatmap-seat seatmap-available',
  s: 'seatmap-seat seatmap-sold',
  p: 'seatmap-seat seatmap-pending',
  c: 'seatmap-seat seatmap-in-cart',
};

const ACTIVE_CLASS = 'seatmap-seat-active';

const UNAVAILABLE_CLASS = 'seatmap-seat seatmap-unavailable';

const OVERLAY_ID = 'seatmap-overlay';
const updateSeatStyles = (seats: Record<string, SeatData>) => {
  Object.entries(seats).forEach(([qid, seat]) => {
    const seatNode = document.getElementById(`seatmap-${qid}`) as any as SVGElement;
    const seatClass = SEAT_CLASSES[seat.s] || UNAVAILABLE_CLASS;
    if (!seatNode) return;

    // leave the active seat as is otherwise set the class
    if (seatNode.classList.contains(ACTIVE_CLASS)) return;
    if (seatNode.classList.value != seatClass) seatNode.classList.value = seatClass;
  });
};

const Seatmap: React.FC<Props> = (props) => {
  const {
    errors,
    setErrors,
    seatData,
    updateSeatData,
    selectedQid,
    setSelectedQid,

    setEndpoint,
  } = useSeatmapStore();
  const { cart: currentCart } = seatData;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMap, setLoadingMap] = useState(true);
  const [busy, setBusy] = useState(false);
  const [closed, setClosed] = useState(false);
  const [currentView, setCurrentView] = useState<SeatmapView>('map');
  // const mapContainer = useRef<typeof PublicSeatmapMapContainer>(null);
  const wsSubscriptions = useRef<Record<string, ActionCable.Subscription>>({});
  const actioncable = useRef<ActionCable.Consumer>();
  const initialDataLoad = useRef(true);

  const { afterUpdateCallback, cart: cartFromProps } = props;
  const { seats, allowSelection, possibleItems } = seatData;

  const onSeatData = useCallback(
    (latest: Partial<SeatDataJSON>) => {
      updateSeatData(latest);
    },
    [updateSeatData],
  );

  const onCartData = useCallback(
    (latest: CartData) => {
      updateSeatData({ cart: latest });
    },
    [updateSeatData],
  );

  useEffect(() => {
    if (initialDataLoad.current) return;

    if (seatData && afterUpdateCallback) {
      afterUpdateCallback(seatData);
    }
  }, [afterUpdateCallback, seatData, initialDataLoad, currentCart]);

  useEffect(() => {
    if (currentCart) {
      // NOTIFY THE MOBILE APP WEBVIEW
      emitSeatmapEvent('seatmap:orderUpdated', currentCart);

      // NOTIFY THE WEB FRONT END
      // don't fire if this comes from websockets (with no cart) or on initial load
      if (initialDataLoad.current) {
        document.dispatchEvent(new CustomEvent(CART_UPDATED_EVENT_TYPE, { detail: currentCart }));
        initialDataLoad.current = false;
      }
    }
  }, [currentCart]);

  const onSelectQID = useCallback(
    (qid?: string) => {
      if (selectedQid === qid) return;

      if (qid) {
        const seatData = seats[qid];
        setSelectedQid(qid);
        emitSeatmapEvent('seatmap:selectedSeat', seatDataToEmit(qid, seatData, possibleItems));
      } else {
        setSelectedQid(undefined);
        emitSeatmapEvent('seatmap:deselectedSeat', { qid });
      }
    },
    [selectedQid, seats, possibleItems, setSelectedQid],
  );

  useEffect(() => {
    return () => {
      emitSeatmapEvent('seatmap:unloaded');
      Object.values(wsSubscriptions.current).forEach((subscription) => subscription.unsubscribe());
      wsSubscriptions.current = {};
    };
  }, []);

  const connectWebsocket = () => {
    const { eventID } = props;

    ActionCable.logger.enabled = true;

    // already connected
    if (wsSubscriptions.current[eventID]) {
      console.log(`already connected to ${eventID}`, wsSubscriptions.current);
      return;
    }

    if (!actioncable.current) actioncable.current = ActionCable.createConsumer();

    // ideally we'd be able to pull any backlog from the `since` timestamp here
    wsSubscriptions.current[eventID] = actioncable.current.subscriptions.create(
      {
        channel: 'SeatsChannel',
        id: eventID,
      },
      {
        connected: () => console.info('SeatsChannel connected'),
        received: (latest: Pick<SeatDataJSON, 'seats'>) => {
          console.info('onSeatData from websocket', {
            latest,
            seatData,
          });
          onSeatData(latest);
        },
      },
    );

    console.log('connectWebsocket', wsSubscriptions.current);
  };

  const { orderID, orderToken } = currentCart || {};

  useEffect(() => {
    if (loadingData) return;
    if (orderID && !wsSubscriptions.current['order']) {
      console.log('connecting to order websocket', orderID);
      wsSubscriptions.current['order'] = actioncable.current.subscriptions.create(
        {
          channel: 'OrderChannel',
          order_id: orderID,
          order_token: orderToken,
        },
        {
          connected: () => console.info('SeatsChannel connected'),
          received: (latest: CartData) => {
            console.info('OrderChannel onCartData  from websocket', {
              latest,
            });
            onCartData(latest);
          },
        },
      );
    }

    return () => {
      wsSubscriptions.current['order']?.unsubscribe();
    };
  }, [loadingData, orderID, orderToken, onCartData]);

  useEffect(() => {
    if (props.endpoint) setEndpoint(props.endpoint, props.releaseSeatsEndpoint);
  }, [props.endpoint, props.releaseSeatsEndpoint, setEndpoint]);

  useEffect(() => {
    // wait for remote data to be loaded before merging in cart from props
    if (loadingData) return;

    if (cartFromProps) {
      onCartData(cartFromProps);
    }
  }, [loadingData, cartFromProps, onCartData]);

  const handleMapReady = () => {
    setLoadingMap(false);
    connectWebsocket();
  };

  const handleClose = () => {
    const parentNode = document.getElementById(OVERLAY_ID);
    parentNode.classList.add('closed'); // the containing overlay outside of React
    setSelectedQid(undefined);
    setClosed(true);
  };

  const handleViewToggle = (nextView: SeatmapView) => {
    setCurrentView(nextView);
  };

  const handleApiDone = useCallback(() => {
    setBusy(false);
  }, []);

  const handleApiError = useCallback(
    (error) => {
      console.error('handlePostError', error);
      reportError(error);
      emitSeatmapEvent('seatmap:postError', {
        error,
        success: false,
      });
      // TODO: I18n
      setErrors([
        'Sorry, something went wrong while we were trying to reserve your seats. Please try again.',
      ]);
      setBusy(false);
    },
    [setErrors],
  );

  const handleUpdateItem = useCallback(
    (itemId: string, quantity: number, freeformBasePrice?: number) => {
      if (busy) return false;
      setBusy(true);
      SeatmapApi.updateItem(itemId, quantity, freeformBasePrice)
        .catch(handleApiError)
        .finally(handleApiDone);
    },
    [busy, handleApiError, handleApiDone],
  );

  const handleUpdateItemFreeformPrice = useCallback(
    (itemId: string, freeformBasePrice: number) => {
      if (busy) return false;
      setBusy(true);
      SeatmapApi.updateItemFreeformPrice(itemId, freeformBasePrice)
        .catch(handleApiError)
        .finally(handleApiDone);
    },
    [busy, handleApiError, handleApiDone],
  );

  const handleAddSeat = useCallback(
    (itemId: string, qid: string) => {
      if (!allowSelection || busy) return false;
      setBusy(true);
      SeatmapApi.addSeat(itemId, qid).catch(handleApiError).finally(handleApiDone);
    },
    [allowSelection, busy, handleApiError, handleApiDone],
  );

  const handleRemoveSeat = useCallback(
    (qid) => {
      if (!allowSelection || busy) return false;
      setBusy(true);
      SeatmapApi.removeSeat(qid).catch(handleApiError).finally(handleApiDone);
    },
    [allowSelection, busy, handleApiError, handleApiDone],
  );

  const clearErrors = () => {
    setErrors([]);
  };

  useEffect(() => {
    SeatmapApi.fetchIndex()
      .then(() => {
        setLoadingData(false);
        emitSeatmapEvent('seatmap:ready');
      })
      .catch(handleApiError)
      .finally(handleApiDone);
  }, [handleApiError, handleApiDone, closed]);

  useEffect(() => {
    if (loadingMap || loadingData || busy || closed) return;
    updateSeatStyles(seats);
  }, [loadingMap, loadingData, busy, seats, currentCart, closed]);

  const renderSuspenseLoading = () => {
    return renderTakeover([<p key="loading">Loading...</p>], false);
  };

  const renderTakeover = (messages, renderCloseButton) => {
    if (!messages || messages.length < 1) return false;

    const onCloseClick = (event) => {
      event.preventDefault();
      clearErrors();
    };

    const closeButton = renderCloseButton ? (
      <div className="controls">
        <a className="close" onClick={(event) => onCloseClick(event)}>
          Close
        </a>
      </div>
    ) : (
      false
    );

    return (
      <div className="seatmap-takeover">
        <div className="seatmap-takeover-content">
          {messages}
          {closeButton}
        </div>
      </div>
    );
  };

  const lockUI = () => {
    return busy || loading();
  };

  const loading = () => {
    return loadingData || loadingMap;
  };

  // we override the currentView when there are errors so that they are always
  // displayed on mobile -- this doesn't have any effect on desktop
  const getCurrentView = () => {
    return errors.length > 0 ? 'map' : currentView;
  };

  // const reopen = useCallback(() => {
  //   const parentNode = document.getElementById(OVERLAY_ID);
  //   parentNode.classList.remove('closed'); // the containing overlay outside of React
  //   setLoadingMap(true);
  //   setClosed(false);
  // }, []);

  const renderBody = () => {
    let messages = [];

    if (errors) {
      messages = errors.map((error) => (
        <p key={error} className="seatmap-error">
          {error}
        </p>
      ));
    }
    const renderCloseButton = !lockUI();
    if (busy) messages.push(<p key="busy">Working...</p>);
    if (loading()) messages.push(<p key="loading">Loading...</p>);
    return renderTakeover(messages, renderCloseButton);
  };

  const renderCart = () => {
    if (props.hideCart) return null;
    if (loading()) return <div className="seatmap-cart" />;

    const cart = seatData.cart || { totalItems: 0, remaining: null };
    const disableCheckout = busy || cart.totalItems < 1;

    const checkoutButton = props.hideCheckout ? (
      <LargeCloseButton handleClose={handleClose} />
    ) : (
      <CheckoutButton
        disabled={disableCheckout}
        busy={busy}
        newCheckoutURL={props.newCheckoutURL}
        remaining={cart.remaining}
        totalItems={cart.totalItems}
      />
    );

    return (
      <div className="seatmap-cart" id="seatmap-cart">
        <TicketList
          handleUpdateItem={handleUpdateItem}
          handleUpdateItemFreeformPrice={handleUpdateItemFreeformPrice}
          handleRemoveSeat={handleRemoveSeat}
          handleClose={handleClose}
          busy={busy}
          hideCheckout={props.hideCheckout}
        />
        {checkoutButton}
      </div>
    );
  };

  const renderHeader = () => {
    if (props.hideHeader) return null;

    return (
      <SeatmapHeader
        eventTitle={seatData.eventTitle || ''}
        handleClose={handleClose}
        handleViewToggle={handleViewToggle}
        currentView={currentView}
      />
    );
  };

  const klass = ['seatmap-root', `seatmap-${getCurrentView()}-view`];

  if (props.rootClass) {
    klass.push(props.rootClass);
  }

  return (
    <div className={klass.join(' ')}>
      {renderHeader()}
      <div className="seatmap-body">
        <Suspense fallback={renderSuspenseLoading()}>
          <div className="seatmap-container">
            <PublicSeatmapMapContainer
              path={props.svg}
              handleMapReady={handleMapReady}
              handleAddSeat={handleAddSeat}
              handleRemoveSeat={handleRemoveSeat}
              onSelectQID={onSelectQID}
              hideTooltips={props.hideTooltips}
            />
            {renderBody()}
          </div>
        </Suspense>
        {renderCart()}
      </div>
    </div>
  );
};

Seatmap.displayName = 'Seatmap';
export default Seatmap;

export interface Props {
  endpoint: string;
  releaseSeatsEndpoint?: string;
  newCheckoutURL?: string;
  svg: string;
  eventID: string;
  hideHeader?: boolean;
  hideCheckout?: boolean;
  hideCart?: boolean;
  hideTooltips?: boolean;
  afterUpdateCallback?: (data: SeatDataJSON) => void;

  /** optional override used by the admin order adjuster */
  cart?: CartData;

  rootClass?: string;
}

export interface SeatDataJSON {
  allowSelection: boolean;
  cart: CartData;
  // cartSeats: any;
  errors?: string[];
  eventTitle: string;
  // loadingData: boolean;
  possibleItems: PossibleItems;

  /** The admin order adjuster returns HTML from the endpoint - kinda gross, but working for now */
  form?: string;

  /** key is itemId, values are qids */
  qids: Record<string, string[]>;

  /** key is qid, values are seats with possible item IDs */
  seats: Record<string, SeatData>;
  // selectedQID: string | undefined;
  ud: number;
}
