import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SeatData } from '@/seatmap/types';
import { CartData } from '@/frontend/types';
import { SeatDataJSON } from '.';

interface SeatmapState {
  endpoint: string | undefined;
  releaseSeatsEndpoint: string | undefined;
  seatData: SeatDataJSON;
  selectedQid: string | null;
  selectedSeatData: SeatData | undefined;
  errors: string[];
  setEndpoint: (endpoint: string, releaseSeatsEndpoint: string) => void;
  setSeatData: (seatData: SeatDataJSON) => void;
  updateSeatData: (latest: Partial<SeatDataJSON>) => void;
  setSelectedQid: (qid: string | null | undefined) => void;
  setErrors: (errors: string[]) => void;
}

export const useSeatmapStore = create<SeatmapState>()(
  devtools(
    (set, get) => ({
      seatData: {},
      errors: [],

      selectedQid: null,
      setEndpoint: (endpoint, releaseSeatsEndpoint) => set({ endpoint, releaseSeatsEndpoint }),
      setErrors: (errors) => set({ errors }),
      setSeatData: (seatData) => set({ seatData }),
      setSelectedQid: (selectedQid) => {
        const selectedSeatData = get().seatData.seats[selectedQid];
        set({ selectedQid, selectedSeatData });
      },
      updateSeatData: (latest) => {
        // we may have a cart only if this comes from the OrderChannel websocket
        const prev = get().seatData;
        const selectedQid = get().selectedQid;

        // promote errors from the cart for easier querying
        const errors = latest.cart?.errors || [];

        const seatData = mergeSeatData(prev, latest);

        set({
          seatData,
          selectedSeatData: selectedQid ? seatData.seats[selectedQid] : undefined,

          errors,
        });
      },
    }),
    {
      name: 'seatmapStore',
      enabled: true,
      store: 'seatmapStore',
    },
  ),
);

/** Add cartItemId to seats in the current cart for easier rendering later */
const decorateSeatsWithCartItem = (
  seatData: Record<string, SeatData>,
  cart: CartData,
): Record<string, SeatData> => {
  console.log('decorateSeatsWithCartItem', seatData, cart);
  const result = { ...seatData };

  Object.entries(cart.cartItems).forEach(([itemId, cartItem]) => {
    if (!cartItem?.s) return;

    Object.keys(cartItem.s).forEach((qid) => {
      if (result[qid]) {
        result[qid].cartItemId = itemId;
        result[qid].s = 'c'; // ensure status is set to 'current-cart'
      }
    });
  });

  return result;
};

export const mergeSeatData = (prev: SeatDataJSON, latest: Partial<SeatDataJSON>): SeatDataJSON => {
  // seatData may only include the seats key if coming from websockets so we handle that here
  // or it may only include the cart data if coming from the OrderChannel
  const updated = prev;
  const initialLoad = !prev.seats || Object.keys(prev.seats).length === 0;

  if (latest.allowSelection) updated.allowSelection = latest.allowSelection;

  if (latest.cart) {
    const qidsInCart = [];
    if (latest.cart.cartItems) {
      Object.values(latest.cart.cartItems).forEach((item) => {
        if (!item.s) return;
        qidsInCart.push(...Object.keys(item.s));
      });
    }

    const updatedSeats = { ...prev.seats };
    Object.entries(updatedSeats).forEach(([qid, seat]) => {
      if (qidsInCart.includes(qid)) {
        updatedSeats[qid].s = 'c';
      } else if (seat.s === 'c') {
        updatedSeats[qid].s = 'a';
      }
    });
    updated.seats = updatedSeats;
  }

  if (latest.seats) {
    const updatedSeats = { ...prev.seats };
    // merge the updated keys for seats, preserving any keys that already exist
    Object.entries(latest.seats).forEach(([qid, seat]) => {
      if (updatedSeats[qid]) {
        updatedSeats[qid] = { ...updatedSeats[qid], ...seat };
      } else {
        if (!initialLoad) console.warn('mergeSeatData got an unknown seat', qid, seat);
        updatedSeats[qid] = seat;
      }
    });
    updated.seats = updatedSeats;
  }

  if (latest.qids) updated.qids = latest.qids;
  if (latest.ud) updated.ud = latest.ud;
  if (latest.possibleItems) updated.possibleItems = latest.possibleItems;
  if (latest.form) updated.form = latest.form;

  // updates from websockets do not contain cart data
  if (latest.cart) {
    updated.cart = latest.cart;
  }

  if (updated.cart) {
    updated.seats = decorateSeatsWithCartItem(updated.seats, updated.cart);
  }
  return updated;
};
