import React, { useEffect, useState } from 'react';
import { useSeatmapStore } from '@/seatmap/seatmapStore';
import { Tooltip } from '@/seatmap/Tooltip';
import { SeatData } from '@/seatmap/types';
import { ZoomTransform } from 'd3';

const SEAT_STATUSES = {
  a: 'Available',
  s: 'Unavailable',
  p: 'Unavailable',
  u: 'Unavailable',
  c: 'In your order',
};

interface Props {
  selectedQid: string | undefined;
  seatElement: SVGElement;
  // selectedSeatData: SeatData;
  handleAddSeat: (itemId: string, qid: string) => void;
  handleRemoveSeat: () => void;
  handleClose: () => void;
  currentTransform: ZoomTransform;
}

// Main tooltip for adding or remove seats for the public
export const SeatTooltip: React.FC<Props> = (props) => {
  const { seatData, selectedSeatData } = useSeatmapStore();
  const { seatElement, handleClose, currentTransform, selectedQid } = props;
  const { allowSelection, possibleItems } = seatData;

  function getSelectedItemId(sd: SeatData) {
    if (!sd || !sd.i) return null;
    return sd.cartItemId ?? sd.i[0];
  }

  const [selectedItemId, setSelectedItemId] = useState(getSelectedItemId(selectedSeatData));

  useEffect(() => {
    setSelectedItemId(getSelectedItemId(selectedSeatData));
  }, [selectedSeatData]);

  function handleAddSeat() {
    props.handleAddSeat(selectedItemId, selectedQid);
  }

  function handleRemoveSeat() {
    props.handleRemoveSeat();
  }

  function handleItemChange(event) {
    setSelectedItemId(event.currentTarget.value);
  }

  function cartItemChanged() {
    if (!selectedSeatData.cartItemId) return false;
    return selectedSeatData.cartItemId != selectedItemId;
  }

  function itemDescription(itemId: string, withPrice = true) {
    const item = possibleItems[itemId];
    if (!item) {
      console.warn('could not load itemDescription for itemId', itemId);
      return false;
    }
    if (item.itemPriceMode === 'freeform_price_mode') {
      return withPrice ? `${item.title} - PWYC` : item.title;
    }

    return withPrice ? `${item.title} - ${item.price}` : item.title;
  }

  function renderMulti(itemIds) {
    return itemIds.map((itemId) => {
      const item = possibleItems[itemId];
      if (!item) {
        console.warn('could not load itemDescription for itemId', itemId);
        return false;
      }
      return (
        <div key={itemId}>
          <label>
            <input
              type="radio"
              name="itemId"
              className="radio"
              checked={selectedItemId == itemId}
              value={itemId}
              onChange={handleItemChange}
            />
            {itemDescription(itemId, true)}
          </label>
        </div>
      );
    });
  }

  function renderBody(hasAction) {
    if (!selectedSeatData.i) {
      console.warn('no items for seatData', selectedSeatData);
      return false;
    }

    // skip the body if the seat isn't available or in the current order
    if (selectedSeatData.s !== 'a' && selectedSeatData.s !== 'c') {
      return false;
    }

    if (selectedSeatData.i.length === 1) {
      return itemDescription(selectedSeatData.i[0], false);
    }

    if (hasAction) {
      return <div>{renderMulti(selectedSeatData.i)}</div>;
    }

    // not able to add to cart so just output text
    const text = selectedSeatData.i.map((itemId) => (
      <div key={itemId}>{itemDescription(itemId, false)}</div>
    ));

    return <div>{text}</div>;
  }

  if (!seatElement) return false;

  if (!selectedSeatData) {
    return <Tooltip seatElement={seatElement} title="Not available" handleClose={handleClose} />;
  }

  let action, onAction;

  if (allowSelection) {
    if (selectedSeatData.s === 'a') {
      action = 'Add to order';
      onAction = handleAddSeat;
    } else if (selectedSeatData.s === 'c') {
      if (cartItemChanged()) {
        action = 'Update your order';
        onAction = handleAddSeat;
      } else {
        action = 'Remove from your order';
        onAction = handleRemoveSeat;
      }
    }
  }

  return (
    <Tooltip
      key={selectedQid}
      seatElement={seatElement}
      title={selectedSeatData.d}
      subtitle={SEAT_STATUSES[selectedSeatData.s]}
      action={action}
      onAction={onAction}
      handleClose={handleClose}
      body={renderBody(onAction)}
    />
  );
};

SeatTooltip.displayName = 'SeatTooltip';
