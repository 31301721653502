import { useSeatmapStore } from '@/seatmap/seatmapStore';
import { seatmapXhr } from '@/seatmap/seatmapXhr';
import { SeatDataJSON } from '.';
import { UpdateCartParams } from '../frontend/types';

export const fetchIndex = () => {
  const { endpoint, updateSeatData } = useSeatmapStore.getState();
  return seatmapXhr<SeatDataJSON>(endpoint, 'GET', {}).then(updateSeatData).catch(console.error);
};

export const addSeat = (itemId: string, qid: string) => {
  const { seatData, endpoint, updateSeatData } = useSeatmapStore.getState();

  if (!seatData.allowSelection) return Promise.reject(new Error('Seat selection not allowed'));

  // this will include any existing QIDs as well as pre-set PWYC pricing
  const params = buildBaseCartForPosting(seatData);

  // to support swapping seat item-types we need to check if the qid we are adding had previously been assigned
  // and remove it if so

  for (const [itemId, cartItem] of Object.entries(params.items)) {
    if (!cartItem.qids) continue;
    params.items[itemId].qids = cartItem.qids
      .split(',')
      .filter((existingQid) => existingQid !== qid)
      .join(',');
  }

  // now add the seat
  if (!params.items[itemId]) params['items'][itemId] = { qids: '' };
  if (params['items'][itemId].qids) {
    params['items'][itemId].qids += `,${qid}`;
  } else {
    params['items'][itemId].qids = qid;
  }

  params['items'][itemId]['freeform_base_price'] = calculateFreeformBasePrice(
    itemId,
    seatData,
  )?.toFixed(2);

  console.log('addSeat', { itemId, qid, params });

  return seatmapXhr<SeatDataJSON>(endpoint, 'PUT', params)
    .then(updateSeatData)
    .catch(console.error);
};

export const removeSeat = (qid) => {
  const { seatData, endpoint, updateSeatData } = useSeatmapStore.getState();
  if (!seatData.allowSelection) return Promise.reject();

  const params = buildBaseCartForPosting(seatData);
  for (const [itemId, cartItem] of Object.entries(params.items)) {
    console.log('maybe filtering', itemId, cartItem);
    if (!cartItem.qids) continue;

    params.items[itemId].qids = cartItem.qids
      .split(',')
      .filter((existingQid) => existingQid !== qid)
      .join(',');
  }

  return seatmapXhr<SeatDataJSON>(endpoint, 'PUT', params)
    .then(updateSeatData)
    .catch(console.error);
};

export const updateItem = (itemId: string, quantity: number, freeformBasePrice?: number) => {
  const { seatData, endpoint, updateSeatData } = useSeatmapStore.getState();
  const params = buildBaseCartForPosting(seatData);
  if (!params.items[itemId]) params.items[itemId] = {};
  params.items[itemId]['quantity'] = quantity;
  params.items[itemId]['qids'] = ''; // reset any selected seats to allow new "best available" selection

  if (freeformBasePrice) {
    params.items[itemId]['freeform_base_price'] = freeformBasePrice?.toFixed(2);
  }

  return seatmapXhr<SeatDataJSON>(endpoint, 'PUT', params)
    .then(updateSeatData)
    .catch(console.error);
};

/** Updates the PWYC price without changes seat QIDs */
export const updateItemFreeformPrice = (itemId: string, freeformBasePrice: number) => {
  const { endpoint, updateSeatData, seatData } = useSeatmapStore.getState();
  const params = buildBaseCartForPosting(seatData);

  if (!params.items[itemId]) params.items[itemId] = {};
  params.items[itemId]['freeform_base_price'] = freeformBasePrice.toFixed(2);

  return seatmapXhr<SeatDataJSON>(endpoint, 'PUT', params)
    .then(updateSeatData)
    .catch(console.error);
};

export const releaseSeats = () => {
  const { releaseSeatsEndpoint, updateSeatData, setSelectedQid } = useSeatmapStore.getState();
  console.warn('calling releaseSeats', releaseSeatsEndpoint);
  return seatmapXhr<SeatDataJSON>(releaseSeatsEndpoint, 'POST', {})
    .then(updateSeatData)
    .then(() => setSelectedQid(null))
    .catch(console.error);
};

export const clearSelection = () => {
  const { setSelectedQid } = useSeatmapStore.getState();
  setSelectedQid(null);
};

/** Build the HTTP request params based on the current cart's contents.
 *
 * The params can then by modified before sending the update XHR call.
 */
export const buildBaseCartForPosting = (seatData: SeatDataJSON): UpdateCartParams => {
  const params: UpdateCartParams = { items: {}, since: seatData.ud };

  Object.entries(seatData.cart.cartItems).forEach(([itemId, cartItem]) => {
    params['items'][itemId] = {};

    if (cartItem.s) {
      // keep any selected QIDs
      params['items'][itemId]['qids'] = Object.keys(cartItem.s).join(',');
    } else {
      params['items'][itemId]['quantity'] = cartItem.q;
    }

    // ensure freeform items retain their pricing
    params['items'][itemId]['freeform_base_price'] = calculateFreeformBasePrice(
      itemId,
      seatData,
    )?.toFixed(2);
  });

  return params;
};

/**
 * Determine the PWYC price by first looking at the price already set in the cart, falling back to
 * the item's basePrice.
 */
export const calculateFreeformBasePrice = (
  itemId: string,
  seatData: SeatDataJSON,
): number | undefined => {
  const item = seatData.possibleItems[itemId];
  const cartItem = seatData.cart?.cartItems[itemId];

  if (!item) {
    console.error('calculateFreeformBasePrice item not found', itemId);
    return undefined;
  }

  // console.log('calculateFreeformBasePrice', { itemId, item, cartItem });

  if (item.itemPriceMode !== 'freeform_price_mode') return undefined;

  // prefer existing price in cart
  const result = cartItem?.base_price || item.basePrice;

  return result;
};
