import React, { MouseEvent } from 'react';

interface Props {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleResetZoom?: () => void;
}

export const ZoomControls: React.FC<Props> = ({ handleZoomIn, handleZoomOut, handleResetZoom }) => {
  const handleResetZoomClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleResetZoom();
  };

  const handleZoomInClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleZoomIn();
  };

  const handleZoomOutClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleZoomOut();
  };

  const renderControl = (
    label: string,
    className: string,
    onClick: (event: MouseEvent<HTMLAnchorElement>) => void,
  ) => {
    return (
      <a key={label} className={`control ${className}`} onClick={onClick}>
        {label}
      </a>
    );
  };

  const controls = [
    renderControl('+', 'zoomin', handleZoomInClick),
    renderControl('-', 'zoomout', handleZoomOutClick),
  ];

  if (handleResetZoom) {
    controls.push(renderControl('∞', 'reset', handleResetZoomClick));
  }

  return <div className="seatmap-zoom-controls">{controls}</div>;
};
