import React, { useCallback, useState } from 'react';
import { FreeformPreset } from '@/components/PwycSelector/types';
import { type PossibleItem } from '@/seatmap/types';
import { TicketListQuantity } from './TicketListQuantity';

interface Props {
  items: Record<string, PossibleItem>;
  handleUpdateItem: (itemId: string, quantity: number, freeformBasePrice?: number) => void;
  busy?: boolean;
}

export const TicketListAddItem: React.FC<Props> = ({ items, handleUpdateItem, busy }) => {
  const getInitialItemId = useCallback(() => {
    const itemIds = Object.keys(items);
    return itemIds.length === 1 ? itemIds[0] : '-1';
  }, [items]);

  const getInitialQuantity = useCallback(
    (itemId: string) => {
      const max = itemId !== '-1' ? items[itemId]?.max : 16;
      return Math.min(2, max || 16);
    },
    [items],
  );

  const [itemId, setItemId] = useState(getInitialItemId());
  const [quantity, setQuantity] = useState(getInitialQuantity(itemId));
  const selectedItem = items[itemId];

  // useEffect(() => {
  //   if (busy || (itemId && quantity >= 0)) return;

  //   const newItemId = getInitialItemId();
  //   const newQuantity = getInitialQuantity(newItemId);

  //   // setDisabled(false);
  //   setItemId(newItemId);
  //   setQuantity(newQuantity);
  // }, [items, busy, getInitialItemId, getInitialQuantity]);

  const handleItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemId(event.target.value);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const item = items[itemId];
    let freeformBasePrice: number | undefined = undefined;
    if (item.itemPriceMode === 'freeform_price_mode') {
      freeformBasePrice = Object.values(item.freeformPresets ?? {}).find(
        (preset: FreeformPreset) => preset.default,
      )?.amount;

      freeformBasePrice ??= item.basePrice;
    }

    handleUpdateItem(itemId, quantity, freeformBasePrice);
  };

  const renderControls = () => {
    const itemSelected = itemId !== '-1';
    const disableSubmit = busy || !itemSelected;

    const min = itemSelected ? (selectedItem?.min ?? 1) : 1;
    const max = itemSelected ? (selectedItem?.max ?? 16) : 16;
    const selectQuantity = Math.min(quantity, max);

    if (max < 1) {
      return <div className="soldout">Sold out</div>;
    }

    return (
      <div>
        <TicketListQuantity
          min={min}
          max={max}
          value={selectQuantity}
          onChange={setQuantity}
          disabled={busy}
        />
        <button type="submit" disabled={disableSubmit} className="seatmap-cart-button">
          Add
        </button>
      </div>
    );
  };

  if (Object.keys(items).length < 1) return null;

  const itemOptions = Object.entries(items).map(([id, ticketType]) => (
    <option key={id} value={id}>
      {ticketType.title}
    </option>
  ));

  if (itemOptions.length > 1) {
    itemOptions.unshift(
      <option key="default" value="-1">
        Select ticket type
      </option>,
    );
  }

  return (
    <div className="seatmap-ticket-type">
      <form onSubmit={onSubmit} className="form">
        <div className="seatmap-cart-select seatmap-cart-select-type">
          <select value={itemId} onChange={handleItemChange} disabled={busy}>
            {itemOptions}
          </select>
        </div>
        {renderControls()}
      </form>
    </div>
  );
};

TicketListAddItem.displayName = 'TicketListAddItem';
