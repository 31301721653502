import React from 'react';
import { type CartDataItem } from '@/frontend/types';
import { useSeatmapStore } from '@/seatmap/seatmapStore';
import type { PossibleItem } from '@/seatmap/types';
import { TicketListAddItem } from './TicketListAddItem';
import { TicketListCurrentItem } from './TicketListCurrentItem';
import { TicketListOtherItems } from './TicketListOtherItems';

interface Props {
  busy?: boolean;
  hideCheckout?: boolean;
  handleUpdateItem: (itemId: string, quantity: number, freeformBasePrice?: number) => void;
  handleUpdateItemFreeformPrice: (itemId: string, freeformBasePrice: number) => void;
  handleRemoveSeat: (seatId: string) => void;
  handleClose: () => void;
}

export const TicketList = (props: Props) => {
  const { seatData, selectedQid } = useSeatmapStore();
  const { cart: currentCart } = seatData;
  const { allowSelection, possibleItems } = seatData;
  const cartItems = currentCart?.cartItems || {};
  const cartSeatItems: Record<string, CartDataItem> = {}; // assigned seating items in the cart
  const otherSeatItems: Record<string, PossibleItem> = {}; // assigned seating items not in the cart
  const cartOtherItems: Record<string, CartDataItem> = {}; // either general admission cart items or items from a different event

  const currentEventSeatItemIds = Object.keys(possibleItems);

  for (const itemId of Object.keys(cartItems)) {
    const cartItem = cartItems[itemId];
    if (cartItem.s && currentEventSeatItemIds.includes(itemId)) {
      cartSeatItems[itemId] = cartItem;
    } else {
      cartOtherItems[itemId] = cartItem;
    }
  }

  const cartItemIds = Object.keys(cartItems);

  for (const itemId of currentEventSeatItemIds) {
    if (cartItemIds.indexOf(itemId) > -1) continue;
    otherSeatItems[itemId] = possibleItems[itemId];
  }

  const currentItems = Object.entries(cartSeatItems).map(([itemId, cartSeatItem]) => {
    const seatSelectedItem: PossibleItem = possibleItems[itemId];

    return (
      <TicketListCurrentItem
        key={itemId}
        itemId={itemId}
        item={seatSelectedItem}
        cartItem={cartSeatItem}
        allowSelection={allowSelection}
        handleUpdateItem={props.handleUpdateItem}
        handleUpdateItemFreeformPrice={props.handleUpdateItemFreeformPrice}
        handleRemoveSeat={props.handleRemoveSeat}
        busy={props.busy}
        selectedQID={selectedQid}
      />
    );
  });

  const handleClose = (event) => {
    event.preventDefault();
    props.handleClose();
  };

  const renderBusy = () => {
    if (props.busy) return false;
    if (props.hideCheckout) return false;
    return (
      <div className="seatmap-cart-close">
        <p>
          <a href="#" onClick={handleClose}>
            Save and close
          </a>{' '}
          to find other tickets.
        </p>
      </div>
    );
  };

  return (
    <div className="seatmap-ticket-list">
      {currentItems}
      <TicketListAddItem
        items={otherSeatItems}
        handleUpdateItem={props.handleUpdateItem}
        busy={props.busy}
      />

      <TicketListOtherItems items={cartOtherItems} />
      {renderBusy()}
    </div>
  );
};

TicketList.displayName = 'TicketList';
