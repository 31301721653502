import React, { useEffect, useState } from 'react';

const formatSeconds = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  if (seconds <= 9) return `${minutes}:0${seconds}`;
  return `${minutes}:${seconds}`;
};

interface CheckoutButtonProps {
  disabled?: boolean;
  busy?: boolean;
  remaining?: number;
  newCheckoutURL: string;
  totalItems: number;
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  disabled,
  busy,
  remaining: initialRemaining,
  newCheckoutURL,
  totalItems,
}) => {
  const [remaining, setRemaining] = useState(initialRemaining);

  useEffect(() => {
    if (!busy) {
      setRemaining(initialRemaining);
    }
  }, [busy, initialRemaining]);

  useEffect(() => {
    const tick = () => {
      setRemaining((prevRemaining) => {
        if (prevRemaining < 1) {
          clearInterval(interval);
          return 0;
        }
        return prevRemaining - 1;
      });
    };

    let interval;
    if (remaining) {
      interval = setInterval(tick, 1000);
    }

    return () => clearInterval(interval);
  }, [remaining]);

  const renderCountdown = () => {
    if (!remaining || remaining < 0) return false;

    const classNames = ['cart-countdown'];
    if (remaining <= 60) {
      classNames.push('danger');
    }

    return <span className={classNames.join(' ')}>{formatSeconds(remaining)}</span>;
  };

  const renderQuantity = () => {
    if (totalItems < 1) return false;

    return <span className="cart-quantity cart-quantity-count">{totalItems}</span>;
  };

  const renderBody = () => {
    const body = (
      <span className="nav-item-link-text">
        Checkout
        {renderQuantity()} {renderCountdown()}
      </span>
    );

    if (disabled) {
      return <div className="btn-store disabled">{body}</div>;
    }

    return (
      <a className="btn-store" href={newCheckoutURL} rel="nofollow">
        {body}
      </a>
    );
  };

  return (
    <div className="nav-item-checkout checkout-with-status-btn seatmap-checkout">
      {renderBody()}
    </div>
  );
};

export default CheckoutButton;
