import getCoordinatesForSeat from '@/seatmap/getCoordinatesForSeat';
import React from 'react';

const OFFSET = 20;
const INITIAL_STATE = {
  frameHeight: null,
  frameWidth: null,
  tooltipWidth: null,
  tooltipHeight: null,
};

interface State {
  frameHeight: number | null | undefined;
  frameWidth: number | null | undefined;
  tooltipWidth: number | null | undefined;
  tooltipHeight: number | null | undefined;
}

interface Props {
  /** Label for the action button */
  action?: string;
  onAction?: () => void;
  handleClose: () => void;
  seatElement?: SVGElement;
  title?: string;
  subtitle?: string;
  buttonClass?: string;
  body?: React.ReactNode;
}

export class Tooltip extends React.Component<Props, State> {
  container: HTMLDivElement | null;
  frame: HTMLDivElement | null;

  constructor(props) {
    super(props);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = Object.assign({}, INITIAL_STATE);
  }

  componentDidMount() {
    this.setState(this.getDimensions());
  }

  getDimensions() {
    if (!this.container) return null;
    if (!this.frame) this.frame = document.querySelector<HTMLDivElement>('.seatmap-map-frame');
    if (!this.frame) return null;

    const frameHeight = this.frame.offsetHeight;
    const frameWidth = this.frame.offsetWidth;
    const tooltipHeight = this.container.offsetHeight;
    const tooltipWidth = this.container.offsetWidth;

    if (
      frameWidth === this.state.frameWidth &&
      frameHeight === this.state.frameHeight &&
      tooltipWidth === this.state.tooltipWidth &&
      tooltipHeight === this.state.tooltipHeight
    )
      return null;

    return { frameWidth, frameHeight, tooltipWidth, tooltipHeight };
  }

  handleActionClick(event) {
    event.preventDefault();
    this.props.onAction();
  }

  handleClose(event) {
    event.preventDefault();
    this.props.handleClose();
  }

  renderTitle() {
    return this.props.title ? <div className="title">{this.props.title}</div> : false;
  }

  renderSubtitle() {
    return this.props.subtitle ? <div className="subtitle">{this.props.subtitle}</div> : false;
  }

  renderClose() {
    if (!this.props.handleClose) return false;
    return (
      <a href="#" className="tooltip-close" onClick={this.handleClose}>
        <span className="ti ti-close" />
      </a>
    );
  }

  renderControls() {
    if (!this.props.action) return false;

    const buttonProps = this.props.onAction
      ? { onClick: this.handleActionClick }
      : { disabled: true };

    const buttonClass = this.props.buttonClass ? this.props.buttonClass : 'btn btn-primary btn-xs';

    return (
      <div className="controls">
        <button className={buttonClass} {...buttonProps}>
          {this.props.action}
        </button>
      </div>
    );
  }

  renderBody() {
    if (!this.props.action && !this.props.body) return false;

    return (
      <div className="seatmap-tooltip-body">
        {this.props.body}
        {this.renderControls()}
      </div>
    );
  }

  constrainCoordinates(coordinates: DOMPoint) {
    coordinates.y += OFFSET;

    const { tooltipWidth, tooltipHeight, frameWidth, frameHeight } = this.state;

    if (coordinates.x < 0) {
      coordinates.x = OFFSET;
    }

    if (coordinates.y < 0) {
      coordinates.y = OFFSET;
    }

    if (tooltipHeight && frameHeight) {
      if (coordinates.y + tooltipHeight > frameHeight) {
        coordinates.y = frameHeight - tooltipHeight - OFFSET;
      }
    }

    if (tooltipWidth && frameWidth) {
      if (coordinates.x + tooltipWidth > frameWidth) {
        coordinates.x = frameWidth - tooltipWidth - OFFSET;
      }
    }

    return coordinates;
  }

  render() {
    const { seatElement } = this.props;
    if (!seatElement) return false;

    let coordinates = getCoordinatesForSeat(seatElement);
    let style = {};
    if (coordinates) {
      coordinates = this.constrainCoordinates(coordinates);
      style = {
        transform: `translate(${coordinates.x}px, ${coordinates.y}px)`,
      };
    }

    const body = this.renderBody();
    const klass = body ? 'seatmap-tooltip' : 'seatmap-tooltip no-body';

    return (
      <div
        className={klass}
        style={style}
        onClick={(event) => event.stopPropagation()}
        ref={(container) => (this.container = container)}
      >
        <div className="seatmap-tooltip-header">
          <div className="titles">
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          {this.renderClose()}
        </div>
        {body}
      </div>
    );
  }
}
