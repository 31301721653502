import React from 'react';

interface Props {
  min: number;
  max: number;
  value: number;
  disabled: boolean;
  onChange: (value: number) => void;
}

export const TicketListQuantity: React.FC<Props> = ({ min, max, value, disabled, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(Number(event.target.value));
  };

  const quantityOptions = [
    <option value={0} key={0}>
      0
    </option>,
    ...Array.from({ length: max - min + 1 }, (_, index) => {
      const quantity = min + index;
      return (
        <option value={quantity} key={quantity}>
          {quantity}
        </option>
      );
    }),
  ];

  return (
    <div className="seatmap-cart-select seatmap-cart-select-quantity">
      <select value={value} onChange={handleChange} disabled={disabled}>
        {quantityOptions}
      </select>
    </div>
  );
};

TicketListQuantity.displayName = 'TicketListQuantity';
