import React from 'react';

interface Props {
  handleClose: () => void;
}

const LargeCloseButton: React.FC<Props> = (props) => {
  return (
    <div className="nav-item-checkout checkout-with-status-btn seatmap-checkout">
      <a className="btn-store" href="#" onClick={props.handleClose}>
        <span className="nav-item-link-text">Close</span>
      </a>
    </div>
  );
};
LargeCloseButton.displayName = 'LargeCloseButton';

export default LargeCloseButton;
