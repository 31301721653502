import React, { useEffect, useState } from 'react';
import { CartDataItem } from '@/frontend/types';
import { PossibleItem } from '@/seatmap/types';
import CurrentSeat from './CurrentSeat';
import { TicketListPwyc } from './TicketListPwyc';
import { TicketListQuantity } from './TicketListQuantity';

interface Props {
  allowSelection?: boolean;
  itemId: string;
  item: PossibleItem;
  cartItem: CartDataItem;
  busy?: boolean;
  handleUpdateItem: (itemId: string, quantity: number, freeformBasePrice?: number) => void;
  handleUpdateItemFreeformPrice: (itemId: string, freeformBasePrice: number) => void;
  handleRemoveSeat: (seatId: string) => void;
  selectedQID?: string;
}

export const TicketListCurrentItem: React.FC<Props> = (props) => {
  const {
    cartItem,
    busy,
    itemId,
    item,
    handleUpdateItem,
    handleUpdateItemFreeformPrice,
    handleRemoveSeat,
    selectedQID,
    allowSelection,
  } = props;

  const itemSeats = cartItem.s;

  const { title, min, max, itemPriceMode } = item;
  const initialQuantity = Object.keys(itemSeats).length;
  const [basePrice, setBasePrice] = useState(cartItem.base_price); // [state, setState
  const [quantity, setQuantity] = useState(initialQuantity);
  const [quantityChanged, setQuantityChanged] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (busy) {
      setDisabled(true);
      return;
    }
    const nextQuantity = Object.keys(itemSeats).length;
    setQuantity(nextQuantity);
    setQuantityChanged(false);
    setDisabled(false);
  }, [itemSeats, busy]);

  const onQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
    setQuantityChanged(newQuantity !== initialQuantity);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleUpdateItem(itemId, quantity, basePrice);
  };

  const renderQuantityWarning = () => {
    if (!quantityChanged) return null;

    if (quantity < 1) {
      return <p className="warning">Clicking search will remove this ticket completely</p>;
    }

    return (
      <p className="warning">
        Changing the quantity may result in completely different seats being selected
      </p>
    );
  };

  const individualSeats = Object.entries(itemSeats).map(([qid, seat]) => (
    <CurrentSeat
      {...seat}
      qid={qid}
      key={qid}
      highlighted={qid === selectedQID}
      price={basePrice}
      itemTitle={title}
      allowSelection={allowSelection}
      handleRemoveSeat={handleRemoveSeat}
    />
  ));

  const renderPwyc = () => {
    if (itemPriceMode !== 'freeform_price_mode') return null;
    const currentPrice = parseFloat((cartItem.ff ?? '0').replace(/[^\d.]/g, ''));

    return (
      <TicketListPwyc
        item={item}
        currentPrice={currentPrice}
        onPriceChange={(newPrice: number) => {
          setBasePrice(newPrice);
          handleUpdateItemFreeformPrice(itemId, newPrice);
        }}
      />
    );
  };

  return (
    <div className="seatmap-ticket-type seatmap-ticket-type-in-cart">
      <form onSubmit={onSubmit} className="form">
        <span className="current-title">{title}</span>

        <TicketListQuantity
          min={min}
          max={max}
          value={quantity}
          onChange={onQuantityChange}
          disabled={disabled}
        />

        <button type="submit" disabled={disabled} className="seatmap-cart-button">
          Search
        </button>
      </form>
      {renderQuantityWarning()}
      {renderPwyc()}
      {individualSeats}
    </div>
  );
};
TicketListCurrentItem.displayName = 'TicketListCurrentItem';
