// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
const currencyFormatting: Intl.NumberFormatOptions = {
  style: 'currency',
  currencyDisplay: 'symbol',
  currency: 'CAD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const currencyFormattingWithoutCents: Intl.NumberFormatOptions = {
  ...currencyFormatting,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const locale = 'en-CA';

export default function (
  value: number | string,
  cents = false,
  showCurrencySymbol = true,
  useGrouping = true,
): string {
  const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^\d.]/g, '')) : value;
  const options = cents ? { ...currencyFormatting } : { ...currencyFormattingWithoutCents };

  options.useGrouping = useGrouping;
  if (!showCurrencySymbol) {
    options.style = 'decimal';
  }

  return numValue.toLocaleString(locale, options);
}
