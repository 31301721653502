import React from 'react';
import { type CartDataItem } from '@/frontend/types';

interface TicketListOtherItemsProps {
  items: Record<string, CartDataItem>;
}

export const TicketListOtherItems: React.FC<TicketListOtherItemsProps> = ({ items = {} }) => {
  const descriptions = Object.entries(items).map(([itemId, item]) => {
    const label = item.d ? `${item.t} – ${item.d}` : `${item.t} x ${item.q}`;
    return <li key={itemId}>{label}</li>;
  });

  if (descriptions.length < 1) return null;

  return (
    <div className="seatmap-cart-others">
      <h4 className="heading">Also in your order&hellip;</h4>
      <ul>{descriptions}</ul>
    </div>
  );
};

TicketListOtherItems.displayName = 'TicketListOtherItems';
