const TOOLTIP_W = 380;

let svgRoot: SVGSVGElement;
let svgMap: SVGGraphicsElement;
let svgPoint: SVGPoint;

export default function getCoordinatesForSeat(seat: SVGElement): DOMPoint | null {
  try {
    // only needs to be done once
    if (!svgPoint) {
      svgRoot = seat.ownerSVGElement;
      svgPoint = svgRoot.createSVGPoint();
      svgMap = svgRoot.querySelector('.seatmap-map');
    }

    if (!svgMap || !svgPoint || !svgRoot) {
      console.error(`Missing svgRoot, svgPoint, or svgMap`, { svgRoot, svgPoint, svgMap });
      return null;
    }

    svgPoint.x = +seat.getAttribute('cx');
    svgPoint.y = +seat.getAttribute('cy');

    const converted = svgPoint.matrixTransform(svgMap.getCTM());
    converted.x -= TOOLTIP_W / 2;

    return converted;
  } catch (e) {
    console.error(e);
    return null;
  }
}
