import isEmpty from 'lodash/isEmpty';
import d3 from '@/minimal-d3';
import { D3ZoomEvent, ZoomTransform } from 'd3-zoom';
import { Selection } from 'd3-selection';

let isZooming = false,
  svgRoot: Selection<SVGSVGElement, any, HTMLElement, undefined>,
  svgMapGroup,
  svgSectionMasks,
  zoom,
  width,
  height,
  onChangeCallback;

let currentTransform = new ZoomTransform(1, 0, 0);

const MAX_SCALE = 2.5;

type Bounds = number[][];

function zoomToBounds(bounds: Bounds) {
  const dx = bounds[1][0] - bounds[0][0],
    dy = bounds[1][1] - bounds[0][1],
    x = (bounds[0][0] + bounds[1][0]) / 2,
    y = (bounds[0][1] + bounds[1][1]) / 2,
    // D3 doesn't use scaleExtent when calling zoom.transform directly so we
    // manually force it here
    scale = Math.max(0.9 / Math.max(dx / width, dy / height), MAX_SCALE),
    translate = [width / 2 - scale * x, height / 2 - scale * y];

  d3.transition
    .call(svgRoot)
    .duration(750)
    .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
}

export function handleResetZoom() {
  svgRoot.call(zoom.transform, d3.zoomIdentity);
}

function onZoom(event: D3ZoomEvent<SVGSVGElement, unknown>): void {
  // g.style("stroke-width", 1.5 / d3.event.scale + "px");
  currentTransform = event.transform;
  isZooming = currentTransform.k > 1;

  svgMapGroup.attr('transform', event.transform);

  if (onChangeCallback) onChangeCallback(currentTransform);
}

function getNodeBounds(node: SVGGraphicsElement): Bounds {
  const bbox = node.getBBox();

  return [
    [bbox.x, bbox.y],
    [bbox.x + bbox.width, bbox.y + bbox.height],
  ];
}

function onSectionMaskClick() {
  if (isZooming) return;

  const bounds = getNodeBounds(this);
  zoomToBounds(bounds);
}

function animatedScale(scale) {
  svgRoot.transition().duration(750).call(zoom.scaleBy, scale);
}

export function handleZoomIn() {
  animatedScale(1.3);
}

export function handleZoomOut() {
  if (!isZooming) {
    handleResetZoom();
    return;
  }

  animatedScale(0.7);
}

function zoomFilter(event: MouseEvent): boolean {
  // don't start a zoom event if the shift key is being pressed
  // this is required to make drag selection work
  return !event.shiftKey;
}

function parseViewBox() {
  const viewBox = svgRoot.node().getAttribute('viewBox');

  if (isEmpty(viewBox)) {
    throw new Error('No viewBox was found');
  }

  const [x, y, width, height] = viewBox.split(' ').map(parseFloat);
  return { x, y, width, height };
}

export function initSeatmapInteraction(containerSelector, callback) {
  svgRoot = d3.select<SVGSVGElement, any>(`${containerSelector} svg`);

  if (!svgRoot) {
    console.error(`Could not find svgRoot - ${containerSelector} svg`);
    return false;
  }

  if (!svgRoot.node()) {
    console.error(`Could not find svgRoot node - ${containerSelector} svg`);
    return false;
  }

  svgMapGroup = svgRoot.select<SVGElement>('.seatmap-map');
  svgSectionMasks = svgRoot.selectAll('.seatmap-section-masks rect');
  svgSectionMasks.on('click', onSectionMaskClick);
  onChangeCallback = callback;
  ({ width, height } = parseViewBox());

  zoom = d3.zoom().scaleExtent([1, Infinity]).filter(zoomFilter).on('zoom', onZoom);

  svgRoot.call(zoom);

  return svgRoot;
}
