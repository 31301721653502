import React from 'react';
// import { trackOccurrenceEvent } from '../../dashboard/analytics';
import { createRoot } from 'react-dom/client';
import AddOccurrencesToCart from '../components/AddOccurrencesToCart';
import { Locale } from './types';
import { AjaxResponse } from '../components/AddOccurrencesToCart/types';

export default function init() {
  try {
    const container: HTMLDivElement = document.querySelector('#add-occurrences-to-cart');
    if (!container) return;

    const body: HTMLElement = document.body;

    const endpoint = container.dataset.endpoint;
    const title = container.dataset.title;
    const event_id = container.dataset.eventId;
    const json = JSON.parse(container.dataset.json) as AjaxResponse;
    const locale = (body.dataset.locale || 'en') as Locale;
    const root = createRoot(container);
    root.render(
      <AddOccurrencesToCart
        endpoint={endpoint}
        locale={locale}
        event_id={event_id}
        title={title}
        dates={json.dates}
        items={json.items}
        timeslots={json.timeslots}
      />,
    );
  } catch (error) {
    console.error(error);
    return;
  }
}
