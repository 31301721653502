import formatCurrency from '@/components/formatCurrency';
import { type PossibleItem } from '@/seatmap/types';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  item: PossibleItem;
  currentPrice: number;
  onPriceChange: (currentPrice: number) => void;
}

export const TicketListPwyc: React.FC<Props> = ({ item, currentPrice, onPriceChange }) => {
  const { basePrice: minimumPrice } = item;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [pendingPrice, setPendingPrice] = useState<number | undefined>(currentPrice);
  const [inputPrice, setInputPrice] = useState<string>(formatCurrency(currentPrice, true, false));
  const inputRef = useRef<HTMLInputElement>(null);

  const disabled = pendingPrice < minimumPrice;
  const message = `Please enter the price you want to pay per ticket. The mininum is ${formatCurrency(minimumPrice, true, true)}.`;

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const santizedValue = parseFloat(event.target.value.replace(/[^\d.]/g, ''));
    if (isNaN(santizedValue)) {
      setErrorMessage(`Please enter a price of at least ${formatCurrency(minimumPrice, true)}`);
      return;
    }
    setPendingPrice(santizedValue);
    setInputPrice(santizedValue.toString());
  };

  const setPrice = () => {
    const sanitizedValue = parseFloat(inputRef.current!.value.replace(/[^\d.]/g, ''));
    if (isNaN(sanitizedValue)) {
      setErrorMessage(`Please enter a price of at least ${formatCurrency(minimumPrice, true)}`);
      return;
    }

    setInputPrice(formatCurrency(sanitizedValue.toString(), true, false, false)); // no comma grouping for input[type=number]
    setPendingPrice(sanitizedValue);
    if (sanitizedValue < minimumPrice) return;
    onPriceChange(sanitizedValue);
  };

  useEffect(() => {
    if (pendingPrice < minimumPrice) {
      setErrorMessage(`Price must be at least ${formatCurrency(minimumPrice, true)}`);
    } else {
      setErrorMessage(undefined);
    }
  }, [pendingPrice, minimumPrice]);

  return (
    <div
      className={classNames({
        'seatmap-cart-pwyc-container': true,
        'has-error': !!errorMessage,
      })}
    >
      <label htmlFor="freeform-price-input">{message}</label>
      <div className="controls">
        <input
          type="number"
          name="items[893][freeform_base_price]"
          id="freeform-price-input"
          value={inputPrice}
          ref={inputRef}
          step="0.01"
          min={formatCurrency(minimumPrice, true, false, false)}
          required={true}
          onChange={handleInput}
          onBlur={handleInput}
        />
        <button
          type="button"
          className="btn seatmap-cart-button"
          disabled={disabled}
          onClick={setPrice}
        >
          Set price
        </button>
      </div>

      {errorMessage && <p className="seatmap-cart-pwyc-error">{errorMessage}</p>}
    </div>
  );
};

TicketListPwyc.displayName = 'TicketListPwyc';
