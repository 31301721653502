import classNames from 'classnames';
import React, { useMemo, useState, MouseEventHandler } from 'react';
import { PwycSelectorProps } from '.';
import formatCurrency from '../formatCurrency';
import QuantitySelector from '../QuantitySelector';
import { postData } from './api';

export const PwycSelector: React.FC<PwycSelectorProps> = (props) => {
  const { freeformPresets, freeformBasePrice, basePrice, quantity, maxQuantity, itemId } = props;
  const freeformAmounts = useMemo(() => Object.values(freeformPresets), [freeformPresets]);

  const [currentPrice, setCurrentPrice] = useState(
    freeformBasePrice ||
      (freeformAmounts || []).find((option) => option.default)?.amount ||
      freeformAmounts[0]?.amount ||
      0,
  );

  const [currentPriceInput, setCurrentPriceInput] = useState(
    formatCurrency(currentPrice, true, false),
  );

  const matchingOption = freeformAmounts.findIndex((option) => option.amount === currentPrice);
  const [currentOption, setCurrentOption] = useState(matchingOption >= 0 ? matchingOption : null);

  const [lineItemQuantity, setLineItemQuantity] = useState(quantity);
  const priceSatisfied = currentPrice >= basePrice;

  const handleSelectOption = (index: number | null) => {
    setCurrentOption(index);
    if (index !== null) {
      const amount = freeformAmounts[index].amount;
      setCurrentPrice(amount);
      setCurrentPriceInput(formatCurrency(amount, true, false));
    }
  };

  const renderOptions = () => {
    const options = freeformAmounts.map((option, index) => {
      const classes = classNames({
        'pgm-radio-option': true,
        selected: currentOption === index,
      });
      return (
        <div className={classes} key={index}>
          <label>
            <input
              type="radio"
              name="price_selector"
              id={`price_selector_${index}`}
              value={option.amount}
              checked={currentOption === index}
              onChange={() => handleSelectOption(index)}
            />
            <span className="control"></span>
            <span className="desc">{formatCurrency(option.amount, true)}</span>
          </label>
        </div>
      );
    });
    if (options.length > 1) {
      // no need for any radio buttons if there aren't any presets
      options.push(
        <div className="pgm-radio-option">
          <label>
            <input
              type="radio"
              name="price_selector"
              id="price_selector_other"
              value="other"
              checked={currentOption === null}
              onChange={() => handleSelectOption(null)}
            />
            <span className="control"></span>
            <span className="desc">{window.Tickit_Checkout_i18n.select_pwyc_other}</span>
          </label>
        </div>,
      );
    }

    return <div className="form-wrapper donation-presets">{options}</div>;
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const santizedValue = event.target.value.replace(/[^\d.]/g, '');
    const value = parseFloat(santizedValue);

    setCurrentPrice(value);
    setCurrentPriceInput(santizedValue);
    const matchingOption = freeformAmounts.findIndex((option) => option.amount === value);
    setCurrentOption(matchingOption >= 0 ? matchingOption : null);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value.replace(/[^\d.]/g, '')).toFixed(2);
    setCurrentPrice(parseFloat(value));
    setCurrentPriceInput(formatCurrency(value, true, false));
  };

  const renderInput = () => {
    return (
      <div className="form-wrapper donation-freeform-price">
        <input
          type="number"
          name="items[893][freeform_base_price]"
          id="freeform-price-input"
          value={currentPriceInput}
          step="0.01"
          min={formatCurrency(basePrice, true, false)}
          required={true}
          onChange={handleInput}
          onBlur={handleBlur}
        />
        {basePrice > 0 && (
          <small>
            {window.Tickit_Checkout_i18n.select_pwyc_minimum_amount}{' '}
            {formatCurrency(basePrice, true)}
          </small>
        )}
      </div>
    );
  };

  const handleSubmit: MouseEventHandler = async (event) => {
    event.preventDefault();

    try {
      await postData(props.updateEndpoint, itemId, lineItemQuantity, currentPrice);
      if (props.onUpdate) props.onUpdate();
      props.handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <header className="pwyc-selector-header">
        <h3 className="pgm-header-title">{props.itemTitle}</h3>
        <div className="pwyc-selector-quantity-selector">
          <QuantitySelector
            currentQuantity={lineItemQuantity}
            maxQuantity={maxQuantity}
            setLineItemQuantity={setLineItemQuantity}
          />
        </div>
      </header>
      <article>
        <h4>{window.Tickit_Checkout_i18n.select_pwyc_title}</h4>
        {renderOptions()}
        {renderInput()}
      </article>
      <footer>
        <div className="pgm-action-links">
          <button
            className={classNames({
              'btn-sm': true,
              'pgm-primary-action-link': true,
              'btn-primary': priceSatisfied,
            })}
            disabled={!priceSatisfied}
            onClick={handleSubmit}
          >
            {window.Tickit_Checkout_i18n.select_pwyc_update}
          </button>
          <button className="btn-sm btn-outline pgm-close-trigger" onClick={props.handleClose}>
            {window.Tickit_Checkout_i18n.cancel}
          </button>
        </div>
      </footer>
    </>
  );
};
