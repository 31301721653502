import React from 'react';
import formatCurrency from '@/components/formatCurrency';

interface Props {
  handleRemoveSeat: (qid: string) => void;
  price: number;
  d: string;
  s: string;
  itemTitle: string;
  allowSelection?: boolean;
  highlighted?: boolean;
  qid: string;
}

const CurrentSeat: React.FC<Props> = ({
  handleRemoveSeat,
  price,
  d,
  itemTitle,
  allowSelection,
  highlighted,
  qid,
}) => {
  const removeSeat = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    handleRemoveSeat(qid);
  };

  const removeButton = () => {
    if (!allowSelection) return <div>not allowed</div>;
    return (
      <a className="remove" onClick={removeSeat}>
        <span className="ti ti-close" />
      </a>
    );
  };

  const klass = highlighted ? 'seatmap-cart-seat highlighted' : 'seatmap-cart-seat';

  return (
    <div className={klass}>
      <div className="title">
        <div className="description">
          {d}
          <div className="subtitle">{itemTitle}</div>
        </div>
        <div className="price">{formatCurrency(price, true)}</div>
        {removeButton()}
      </div>
    </div>
  );
};

CurrentSeat.displayName = 'CurrentSeat';
export default CurrentSeat;
