import getCSRFToken from '../common/getCSRFToken';

export async function seatmapXhr<T>(endpoint: string, method: string, params: any): Promise<T> {
  params['_method'] = method;
  const response = await fetch(endpoint, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
    credentials: 'include',
    body: method === 'GET' ? undefined : JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const jsonData = await response.json();

  maybeReplaceUrl(jsonData);

  return jsonData as T;
}

export const maybeReplaceUrl = (jsonData: any) => {
  if (!jsonData?.cart?.orderID && !jsonData?.cart?.orderToken) return;

  // const { orderID, orderToken } = jsonData.cart;
  // const currentUrl = window.location.href;
  // const url = new URL(currentUrl);
  // if (!url.searchParams.has('order_id') && !url.searchParams.has('order_token')) {
  //   url.searchParams.set('order_id', orderID);
  //   url.searchParams.set('order_token', orderToken);
  //   alert(url.toString());
  //   window.history.replaceState({}, '', url.toString());
  // }
};
