import jquery from '../common/jquery';
import '../polyfills/frontend';
import {} from 'jquery-ujs';
import assignedSeating from './assignedSeating';
import browserCheck from './browserCheck';
import cancellationPreference from './cancellationPreference';
import cart from './cart';
import cartDonation from './cartDonation';
import cartOccurrences from './cartOccurrences';
import checkout from './checkout';
import contactTracing from './contactTracing';
import detectOS from './detectOS';
import expandables from './expandables';
import expandos from './expandos';
import facebookPixel from './facebookPixel';
import iframeHelpers from './iframeHelpers';
import internalLinks from './internalLinks';
import itemGroups from './itemGroups';
import kb from './kb';
import modalGallery from './modalGallery';
import publicModal from './publicModal';
import pwycSelector from './pwycSelector';
import multipackBuilder from './multipackBuilder';
import navTooltips from './navTooltips';
import orderEmailOptin from './orderEmailOptin';
import orderFacebook from './orderFacebook';
import orderQuestions from './orderQuestions';
import orderQuestionUploader from './orderQuestionUploader';
import relatedStores from './relatedStores';
import scrollToElement from './scrollToElement';
import selectBoxes from './selectBoxes';
import singleTicketDownload from './singleTicketDownload';
import stickyNav from './stickyNav';
import tentativelySoldoutTooltips from './tentativelySoldoutTooltips';
import terms from './terms';
import ticketTransfers from './ticketTransfers';
import updateRemoteProfileForm from './updateRemoteProfileForm';
import { logOrderActivity, trackEvent, trackSocial } from './analytics';

window.$ = $;
window.Tickit = {
  logOrderActivity: logOrderActivity,
  trackEvent: trackEvent,
  trackSocial: trackSocial,
};

export default function init() {
  const body = $('body');
  const cookieDomain = body.attr('data-cd');
  const storeURL = body.attr('data-sd');

  window.Tickit.cookieDomain = cookieDomain;
  window.Tickit.storeURL = storeURL;

  browserCheck(cookieDomain, storeURL);
  detectOS(body);
  iframeHelpers();
  assignedSeating();
  cart(body, storeURL);
  cancellationPreference();
  cartDonation();
  cartOccurrences(body);
  checkout();
  contactTracing();
  expandables();
  expandos();
  facebookPixel(body);
  internalLinks();
  itemGroups();
  kb();
  modalGallery();
  multipackBuilder();
  publicModal();
  pwycSelector();
  navTooltips();
  orderEmailOptin();
  orderFacebook();
  orderQuestions();
  orderQuestionUploader();
  relatedStores();
  scrollToElement();
  selectBoxes();
  singleTicketDownload();
  stickyNav(body);
  tentativelySoldoutTooltips();
  terms();
  ticketTransfers();
  updateRemoteProfileForm();
  body.addClass('docready');
}

$(document).ready(init);
